import { abilitySoundFiles } from "./../../models/AbilitySoundFiles";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { God, gods } from "src/app/models/GodModel";
import { startWith, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { HistoryPlayer } from "../home/home.component";
import { data, allData } from "src/app/models/data";

@Component({
  selector: "app-ability-sounds",
  templateUrl: "./ability-sounds.component.html",
  styleUrls: ["./ability-sounds.component.scss"],
})
export class AbilitySoundsComponent implements OnInit, AfterViewInit {
  godCtrl = new FormControl("");
  filteredGods: Observable<God[]>;
  Gods: God[] = JSON.parse(JSON.stringify(gods));
  godSoundFiles: string[] = JSON.parse(JSON.stringify(abilitySoundFiles));

  abilitiesDisplayed: string[] = [];
  selectedAbilties: string[] = [];

  displayedColumns: string[] = [
    "Ability 1",
    "Ability 2",
    "Ability 3",
    "Ability 4",
  ];

  abilityAudio = new Audio();

  counter = 0;
  todayGodAbilities: string[] = [];
  fullResults: any[] = [];

  completedGodAbilities: string[] = [];

  currentGodAbility: string;
  searchedGodName: string = "";
  selectedNumber = 0;

  isCompleted: boolean = false;
  isSoundPlaying: boolean = false;

  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");
  getDataTries: number = 0;
  isError: boolean = false;
  isSelecting: boolean = false;

  isYmir = false;
  isCompletedAndNoYmir = false;

  ngAfterViewInit() {
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "abilitySoundsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.selectedAbilties = [];
        this.godCtrl.setValue("");
        this.selectedNumber = 0;
        this.abilitiesDisplayed = [];
        this.abilityAudio.pause();
        this.abilityAudio.currentTime = 0;
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredGods = this.godCtrl.valueChanges.pipe(
      startWith(""),
      map((god) => (god ? this.filterGods(god) : []))
    );

    this.decryptTodayAbilitySounds();

    let alreadyCompleted: string[] = [];

    try {
      alreadyCompleted = JSON.parse(
        localStorage.getItem("completedGodAbilitiesSound")
      );
    } catch (error) {
      localStorage.removeItem("completedGodAbilitiesSound");
      localStorage.removeItem("abilitySoundsState");
    }
    this.todayGodAbilities.forEach((todayGodAbility, index) => {
      var fields = todayGodAbility.split("_");
      let godName = fields[0];
      let abilityNumber = fields[1];

      let god = this.Gods.filter((god) => {
        let test = god.name.replace(/ +/g, "");
        return test.replace("'", "").toLowerCase() === godName.toLowerCase();
      })[0];

      this.fullResults.push({
        img: god.image,
        ability: "Ability " + abilityNumber,
        name: todayGodAbility,
        tries: 0,
      });
    });
    if (alreadyCompleted !== null && alreadyCompleted.length > 0) {
      this.completedGodAbilities = alreadyCompleted;
      if (
        this.todayGodAbilities.every((tga) =>
          this.completedGodAbilities.includes(tga)
        )
      ) {
        this.isCompleted = true;
        localStorage.setItem(
          "abilitySoundsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
      } else {
        for (let i = 0; i < 5; i++) {
          if (!this.completedGodAbilities.includes(this.todayGodAbilities[i])) {
            this.counter = i;
            break;
          }
        }
      }
    }
    this.fullResults.forEach((result, index) => {
      let alreadySelectedAbilitiesCounter: string[] = [];
      let tries: number = 0;
      try {
        alreadySelectedAbilitiesCounter = JSON.parse(
          localStorage.getItem("selectedAbilitiesSound_" + (index + 1))
        );
      } catch (error) {
        localStorage.removeItem("selectedAbilitiesSound_" + (index + 1));
        localStorage.removeItem("abilitySoundsState");
        if (this.completedGodAbilities.includes(result.name)) {
          let index = this.completedGodAbilities.indexOf(result.name);
          this.completedGodAbilities.splice(index, 1);
          localStorage.setItem(
            "completedGodAbilitiesSound",
            JSON.stringify(this.completedGodAbilities)
          );
        }
      }

      if (
        alreadySelectedAbilitiesCounter !== null &&
        alreadySelectedAbilitiesCounter.length > 0
      ) {
        tries = alreadySelectedAbilitiesCounter.length;
        result.tries = tries;
      }
    });
    let alreadySelectedAbilities: string[] = [];

    try {
      alreadySelectedAbilities = JSON.parse(
        localStorage.getItem("selectedAbilitiesSound_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("selectedAbilitiesSound_" + (this.counter + 1));
      localStorage.removeItem("abilitySoundsState");
    }

    if (
      alreadySelectedAbilities !== null &&
      alreadySelectedAbilities.length > 0
    )
      this.selectedAbilties = alreadySelectedAbilities;

    this.currentGodAbility = this.todayGodAbilities[this.counter];

    this.getSounds();
    this.abilityAudio.onerror = (event) => {
      this.getSounds();
    };
  }
  ngOnInit() {
    this.fullState = "uncompleted";
    let abilitySoundsState = localStorage.getItem("abilitySoundsState");
    if (abilitySoundsState) {
      const bytes = CryptoJS.AES.decrypt(abilitySoundsState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }

    let todayDuragYmir = localStorage.getItem("duragYmir_today");

    if (todayDuragYmir) {
      const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
      if (bytes.toString()) {
        let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (duragYmirData.spot === "god" && duragYmirData.found === false) {
          this.isYmir = true;
        }
      }
    }

    if (this.isCompleted) {
      if (!this.fullResults.map((result) => result.img).includes("Ymir.png"))
        this.isCompletedAndNoYmir = true;
    }
  }

  duragYmir() {
    let todayDuragYmir = localStorage.getItem("duragYmir_today");

    if (todayDuragYmir) {
      const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
      if (bytes.toString()) {
        let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        duragYmirData.found = true;

        localStorage.setItem(
          "duragYmir_today",
          CryptoJS.AES.encrypt(
            JSON.stringify(duragYmirData),
            this.cryptKey
          ).toString()
        );

        this.router.navigateByUrl("/password");
      }
    }
  }

  decryptTodayAbilitySounds() {
    let todayAbilitySounds = localStorage.getItem("abilitySounds_today");
    if (todayAbilitySounds) {
      const bytes = CryptoJS.AES.decrypt(todayAbilitySounds, this.cryptKey);
      if (bytes.toString()) {
        this.todayGodAbilities = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        while (true) {
          if (
            this.todayGodAbilities.length ===
              new Set(this.todayGodAbilities).size &&
            this.todayGodAbilities.length === 5
          )
            break;
          else {
            this.todayGodAbilities = Array.from(
              new Set(this.todayGodAbilities)
            );

            const randomSoundNumber = Math.floor(
              Math.random() * this.godSoundFiles.length
            );
            let randomSound = this.godSoundFiles[randomSoundNumber];
            this.todayGodAbilities.push(randomSound);
          }
        }
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayAbilitySounds();
    }
  }
  getTodayAbilitySounds() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("abilitySounds_today", data.abilitySounds);
    //     this.decryptTodayAbilitySounds();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("abilitySounds_today", todayData.abilitySounds);
    this.decryptTodayAbilitySounds();
  }

  getSounds() {
    let ability = "assets/ability_sounds/" + this.currentGodAbility + ".aac";

    this.abilityAudio.src = ability;
    this.abilityAudio.volume = 0.3;
    this.abilityAudio.loop = true;
  }

  oldIncludes(number) {
    return this.selectedAbilties.includes(this.searchedGodName + "_" + number);
  }

  correctGuess(number) {
    let selectedAbi = this.searchedGodName + "_" + this.selectedNumber;
    if (
      this.currentGodAbility.toLowerCase() === selectedAbi.toLowerCase() &&
      this.selectedNumber === number
    )
      return "correctCss";
    else if (
      this.selectedAbilties.includes(this.searchedGodName + "_" + number)
    )
      return "incorrectCss";
    return "normalCss";
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    if (event.isUserInput) {
      let searchedGodName = event.source.value;

      this.selectedNumber = 0;
      searchedGodName = searchedGodName.replace(/ +/g, "");
      searchedGodName = searchedGodName.replace("'", "");
      this.searchedGodName = searchedGodName;
      this.abilitiesDisplayed = [
        "assets/ability_images/" + searchedGodName + "_1.png",
        "assets/ability_images/" + searchedGodName + "_2.png",
        "assets/ability_images/" + searchedGodName + "_3.png",
        "assets/ability_images/" + searchedGodName + "_4.png",
      ];

      this.godCtrl.setValue("");
    }
  }

  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    this.abilityAudio.pause();
    this.abilityAudio.currentTime = 0;

    localStorage.setItem(
      "completedGodAbilitiesSound",
      JSON.stringify(this.completedGodAbilities)
    );

    let selectedAbiltiesCounter =
      "selectedAbilitiesSound_" + (this.counter + 1);
    localStorage.setItem(
      selectedAbiltiesCounter,
      JSON.stringify(this.selectedAbilties)
    );
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "abilitySoundsState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.selectedAbilties = [];
      this.godCtrl.setValue("");
      this.selectedNumber = 0;
      this.abilitiesDisplayed = [];
      this.abilityAudio.pause();
      this.abilityAudio.currentTime = 0;
    }
  }

  playSound() {
    if (this.isSoundPlaying) {
      this.isSoundPlaying = false;
      this.abilityAudio.pause();
    } else {
      this.abilityAudio.play();
      this.isSoundPlaying = true;
    }
  }

  selectedAbility(number) {
    if (!this.completedGodAbilities.includes(this.currentGodAbility)) {
      this.isSelecting = true;
      let selectedAbi = this.searchedGodName + "_" + number;
      this.selectedNumber = number;
      if (!this.selectedAbilties.includes(selectedAbi))
        this.selectedAbilties.unshift(selectedAbi);
      this.cdRef.detectChanges();

      let selectedAbiltiesCounter =
        "selectedAbilitiesSound_" + (this.counter + 1);
      localStorage.setItem(
        selectedAbiltiesCounter,
        JSON.stringify(this.selectedAbilties)
      );

      this.fullResults.find(
        (result) => result.name === this.currentGodAbility
      ).tries = this.selectedAbilties.length;

      if (this.currentGodAbility.toLowerCase() === selectedAbi.toLowerCase()) {
        this.completedGodAbilities.unshift(this.currentGodAbility);

        if (
          this.todayGodAbilities.every((tga) =>
            this.completedGodAbilities.includes(tga)
          )
        ) {
          this.fullState = "completed";
          localStorage.setItem(
            "abilitySoundsState",
            CryptoJS.AES.encrypt(
              JSON.stringify("completed"),
              this.cryptKey
            ).toString()
          );
          this.setHistory();
        }
        setTimeout(() => {
          this.abilityAudio.pause();
          this.abilityAudio.currentTime = 0;

          if (
            this.todayGodAbilities.every((tga) =>
              this.completedGodAbilities.includes(tga)
            )
          ) {
            this.isCompleted = true;
            if (document.getElementById("f6"))
              document.getElementById("f6").remove();
          } else {
            while (true) {
              this.counter++;
              if (this.counter > 4) this.counter = 0;
              if (
                !this.completedGodAbilities.includes(
                  this.todayGodAbilities[this.counter]
                )
              )
                break;
            }

            this.currentGodAbility = this.todayGodAbilities[this.counter];
            this.selectedAbilties = [];
            this.godCtrl.setValue("");
            this.selectedNumber = 0;
            this.abilitiesDisplayed = [];
            this.isSoundPlaying = false;
            this.getSounds();
            this.abilityAudio.onerror = (event) => {
              this.getSounds();
            };
          }
          this.isSelecting = false;
        }, 1500);
      } else {
        this.isSelecting = false;
      }
    }
  }

  changeSound(event) {
    this.abilityAudio.pause();
    this.abilityAudio.currentTime = 0;
    if (event === "next") {
      this.counter++;
      if (this.counter > 4) this.counter = 0;
    } else if (event === "previous") {
      this.counter--;
      if (this.counter < 0) this.counter = 4;
    }

    this.currentGodAbility = this.todayGodAbilities[this.counter];
    this.selectedAbilties = [];
    let alreadySelectedAbilities: string[] = [];

    try {
      alreadySelectedAbilities = JSON.parse(
        localStorage.getItem("selectedAbilitiesSound_" + (this.counter + 1))
      );
    } catch (error) {
      localStorage.removeItem("selectedAbilitiesSound_" + (this.counter + 1));
      localStorage.removeItem("abilitySoundsState");
    }

    if (
      alreadySelectedAbilities !== null &&
      alreadySelectedAbilities.length > 0
    )
      this.selectedAbilties = alreadySelectedAbilities;

    this.godCtrl.setValue("");
    this.selectedNumber = 0;
    this.abilitiesDisplayed = [];
    this.isSoundPlaying = false;
    if (this.completedGodAbilities.includes(this.currentGodAbility)) {
      this.godCtrl.disable();
      var fields = this.currentGodAbility.split("_");
      let godName = fields[0];
      let number = fields[1];
      this.searchedGodName = godName;
      this.selectedNumber = parseInt(number);
      this.abilitiesDisplayed = [
        "assets/ability_images/" + godName + "_1.png",
        "assets/ability_images/" + godName + "_2.png",
        "assets/ability_images/" + godName + "_3.png",
        "assets/ability_images/" + godName + "_4.png",
      ];
    } else this.godCtrl.enable();

    this.getSounds();
    this.abilityAudio.onerror = (event) => {
      this.getSounds();
    };
  }

  navigate() {
    this.router.navigateByUrl("/ability-text");
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.abilitySounds =
            this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: "-",
            quote: "-",
            abilitySounds: this.fullState === "completed" ? "W" : "L",
            abilityText: "-",
            vgs: "-",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: "-",
        quote: "-",
        abilitySounds: this.fullState === "completed" ? "W" : "L",
        abilityText: "-",
        vgs: "-",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
