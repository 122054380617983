import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, AboutDialog, HowToDialog } from "./app.component";
import { HomeComponent } from "./modules/home/home.component";
import { GodsComponent } from "./modules/gods/gods.component";
import { QuoteComponent } from "./modules/quote/quote.component";
import { AbilitySoundsComponent } from "./modules/ability-sounds/ability-sounds.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginatorModule,
  MatTooltipModule,
  MatSelectModule,
  MatDialogModule,
} from "@angular/material";
import { AbilityTextComponent } from "./modules/ability-text/ability-text.component";
import { VgsComponent } from "./modules/vgs/vgs.component";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { UniversalDeviceDetectorService } from "./universal-device-detector.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { AdsenseModule } from "ng2-adsense";
import { SplComponent } from "./modules/spl/spl.component";
import { FeudComponent } from "./modules/feud/feud.component";
import { PasswordComponent } from "./modules/password/password.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GodsComponent,
    QuoteComponent,
    AbilitySoundsComponent,
    AbilityTextComponent,
    VgsComponent,
    AboutDialog,
    HowToDialog,
    SplComponent,
    FeudComponent,
    PasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    AdsenseModule.forRoot({
      adClient: "ca-pub-1810744005165953",
    }),
  ],
  providers: [
    DatePipe,
    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AboutDialog, HowToDialog],
})
export class AppModule {}
