import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  OnInit,
} from "@angular/core";
import { Observable } from "rxjs";
import { startWith, map, delay } from "rxjs/operators";
import { FormControl } from "@angular/forms";

import { gods, God } from "../../models/GodModel";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { AngularFirestore } from "@angular/fire/firestore";
import { HistoryPlayer } from "../home/home.component";
import { data, allData } from "src/app/models/data";

@Component({
  selector: "app-gods",
  templateUrl: "./gods.component.html",
  styleUrls: ["./gods.component.scss"],
})
export class GodsComponent implements AfterViewInit, OnDestroy, OnInit {
  godCtrl = new FormControl("");
  filteredGods: Observable<God[]>;
  Gods: God[] = JSON.parse(JSON.stringify(gods));
  GodsDisplayed: God[] = [];

  oldDisplayed: God[] = [];

  displayedColumns: string[] = [
    "Image",
    "Pantheon",
    "Type",
    "Range",
    "Positions",
    "Pros",
  ];

  currentGod: God;

  dataSource = new MatTableDataSource<God>(this.GodsDisplayed);

  isCompleted: boolean = false;
  fullState: string = "uncompleted";

  cryptKey = environment.cryptKey;
  currentD = localStorage.getItem("currentD");

  getDataTries: number = 0;
  isError: boolean = false;

  NJAudio = new Audio();

  isYmir = false;
  isCompletedAndNoYmir = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator.pageSize = 5;
    this.dataSource.paginator = this.paginator;

    let alreadyGuessedGodNames: string[] = [];

    try {
      alreadyGuessedGodNames = JSON.parse(
        localStorage.getItem("guessGodNames")
      );
    } catch (error) {
      localStorage.removeItem("guessGodNames");
      localStorage.removeItem("godsState");
    }

    if (alreadyGuessedGodNames !== null && alreadyGuessedGodNames.length > 0) {
      alreadyGuessedGodNames.forEach((godName) => {
        let godData = this.Gods.find((god) => god.name === godName);
        if (godData !== undefined) {
          this.GodsDisplayed.push(godData);
          let index = this.Gods.indexOf(godData);
          this.Gods.splice(index, 1);
        }
      });
      this.dataSource.data = this.GodsDisplayed;
      this.dataSource.paginator.length = this.GodsDisplayed.length;
      this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));

      if (alreadyGuessedGodNames.includes(this.currentGod.name)) {
        this.isCompleted = true;
        localStorage.setItem(
          "godsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
      }
      this.cdRef.detectChanges();
    }
    if (document.getElementById("f6")) {
      document.getElementById("f6").addEventListener("click", () => {
        this.isCompleted = true;
        this.fullState = "surrender";
        localStorage.setItem(
          "godsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("surrender"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
        this.godCtrl.setValue("");
        document.getElementById("f6").remove();
      });
      if (this.isCompleted) {
        document.getElementById("f6").remove();
      }
    }
    this.NJAudio.src = this.currentGod.NJ_Voiceline!;
    this.NJAudio.volume = 0.5;

    this.NJAudio.load();
  }

  ngOnInit() {
    this.fullState = "uncompleted";
    let godState = localStorage.getItem("godsState");
    if (godState) {
      const bytes = CryptoJS.AES.decrypt(godState, this.cryptKey);
      if (bytes.toString()) {
        let state = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (state === "completed" || state === "surrender") {
          this.isCompleted = true;
          this.fullState = state;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }
    } else {
      this.isCompleted = false;
    }

    let todayDuragYmir = localStorage.getItem("duragYmir_today");

    if (todayDuragYmir) {
      const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
      if (bytes.toString()) {
        let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (duragYmirData.spot === "god" && duragYmirData.found === false) {
          this.isYmir = true;
        }
      }
    }

    if (this.isCompleted) {
      if (
        !this.GodsDisplayed.map((godDisplay) => godDisplay.name).includes(
          "Ymir"
        )
      )
        this.isCompletedAndNoYmir = true;
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: AngularFirestore
  ) {
    this.filteredGods = this.godCtrl.valueChanges.pipe(
      startWith(""),
      map((god) => (god ? this.filterGods(god) : []))
    );
    this.decryptTodayGod();
  }

  duragYmir() {
    let todayDuragYmir = localStorage.getItem("duragYmir_today");

    if (todayDuragYmir) {
      const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
      if (bytes.toString()) {
        let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        duragYmirData.found = true;

        localStorage.setItem(
          "duragYmir_today",
          CryptoJS.AES.encrypt(
            JSON.stringify(duragYmirData),
            this.cryptKey
          ).toString()
        );

        this.router.navigateByUrl("/password");
      }
    }
  }

  decryptTodayGod() {
    let todayGod = localStorage.getItem("gods_today");
    if (todayGod) {
      const bytes = CryptoJS.AES.decrypt(todayGod, this.cryptKey);
      if (bytes.toString()) {
        let todayGodName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        this.currentGod = this.Gods.find((god) => god.name === todayGodName);
        if (this.currentGod === undefined) {
          this.getDataTries++;
        }
      } else {
        this.getDataTries++;
      }
    } else {
      this.getDataTries++;
    }

    if (this.getDataTries > 0) {
      if (this.getDataTries >= 2) this.isError = true;
      else this.getTodayGod();
    }
  }
  getTodayGod() {
    // this.store
    //   .collection("gamemodes")
    //   .doc(this.currentD)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("gods_today", data.gods);
    //     this.decryptTodayGod();
    //   });

    let allDat: data[] = JSON.parse(JSON.stringify(allData));
    let todayData: data = allDat.filter((d) => {
      return d.day === this.currentD;
    })[0];

    localStorage.setItem("gods_today", todayData.gods);
    this.decryptTodayGod();
  }
  oldIncludes(god: God) {
    return this.oldDisplayed.map((oldGod) => oldGod.name).includes(god.name);
  }

  private filterGods(value: string): God[] {
    const filterValue = value.toLowerCase();

    return this.Gods.filter((god) =>
      god.name.toLowerCase().startsWith(filterValue)
    );
  }

  selectedGod(event) {
    if (event.isUserInput) {
      let searchedGodName = event.source.value;
      let searchedGod: God = this.Gods.find(
        (god) => god.name === searchedGodName
      );

      this.GodsDisplayed.unshift(searchedGod);

      let index = this.Gods.indexOf(searchedGod);
      this.Gods.splice(index, 1);

      this.dataSource.data = this.GodsDisplayed;
      this.godCtrl.setValue("");
      this.dataSource.paginator.length = this.GodsDisplayed.length;

      let storageGodNames = this.GodsDisplayed.map((god) => god.name);
      localStorage.setItem("guessGodNames", JSON.stringify(storageGodNames));

      if (searchedGodName === this.currentGod.name) {
        this.fullState = "completed";
        localStorage.setItem(
          "godsState",
          CryptoJS.AES.encrypt(
            JSON.stringify("completed"),
            this.cryptKey
          ).toString()
        );
        this.setHistory();
      }
      setTimeout(() => {
        if (searchedGodName === this.currentGod.name) {
          this.isCompleted = true;
          this.fullState = "completed";

          this.NJAudio.play();
          if (document.getElementById("f6"))
            document.getElementById("f6").remove();
        }
      }, 5500);
    }
  }

  ContainsPositions(chosenGod: God, arr2: any[]) {
    if (
      this.oldDisplayed.map((oldGod) => oldGod.name).includes(chosenGod.name)
    ) {
      if (
        arr2.every((r) => chosenGod.positions.includes(r)) &&
        arr2.length === chosenGod.positions.length
      )
        return "correctCss";
      else if (arr2.some((r) => chosenGod.positions.includes(r)))
        return "partialCss";
      return "incorrectCss";
    } else {
      if (
        arr2.every((r) => chosenGod.positions.includes(r)) &&
        arr2.length === chosenGod.positions.length
      )
        return "correctCssPositions";
      else if (arr2.some((r) => chosenGod.positions.includes(r)))
        return "partialCssPositions";
      return "incorrectCssPositions";
    }
  }

  ContainsPros(chosenGod: God, arr2: any[]) {
    if (
      this.oldDisplayed.map((oldGod) => oldGod.name).includes(chosenGod.name)
    ) {
      if (
        arr2.every((r) => chosenGod.pros.includes(r)) &&
        arr2.length === chosenGod.pros.length
      )
        return "correctCss";
      else if (arr2.some((r) => chosenGod.pros.includes(r)))
        return "partialCss";
      return "incorrectCss";
    } else {
      if (
        arr2.every((r) => chosenGod.pros.includes(r)) &&
        arr2.length === chosenGod.pros.length
      )
        return "correctCssPros";
      else if (arr2.some((r) => chosenGod.pros.includes(r)))
        return "partialCssPros";
      return "incorrectCssPros";
    }
  }

  paginationTest(event) {
    this.oldDisplayed = JSON.parse(JSON.stringify(this.GodsDisplayed));
  }

  navigate() {
    this.router.navigateByUrl("/quote");
  }
  @HostListener("window:beforeunload")
  ngOnDestroy(): void {
    let storageGodNames = this.GodsDisplayed.map((god) => god.name);
    localStorage.setItem("guessGodNames", JSON.stringify(storageGodNames));
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F6" && !this.isCompleted) {
      this.isCompleted = true;
      this.fullState = "surrender";
      localStorage.setItem(
        "godsState",
        CryptoJS.AES.encrypt(
          JSON.stringify("surrender"),
          this.cryptKey
        ).toString()
      );
      this.setHistory();
      this.godCtrl.setValue("");
    }
  }

  setHistory() {
    let history: HistoryPlayer[] = [];
    let historyStorage;

    historyStorage = localStorage.getItem("history");

    if (historyStorage) {
      const bytes = CryptoJS.AES.decrypt(historyStorage, this.cryptKey);
      if (bytes.toString()) {
        history = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let currentDate = localStorage.getItem("currentD");
        let currentHistoryDate = history.find(
          (hist) => hist.date === currentDate
        );
        if (currentHistoryDate) {
          currentHistoryDate.gods = this.fullState === "completed" ? "W" : "L";
        } else {
          history.unshift({
            date: currentDate,
            gods: this.fullState === "completed" ? "W" : "L",
            quote: "-",
            abilitySounds: "-",
            abilityText: "-",
            vgs: "-",
            spl: "-",
            feud: "-",
          });
        }
        localStorage.setItem(
          "history",
          CryptoJS.AES.encrypt(
            JSON.stringify(history),
            this.cryptKey
          ).toString()
        );
      } else {
        localStorage.removeItem("history");
      }
    } else {
      let currentDate = localStorage.getItem("currentD");

      history.push({
        date: currentDate,
        gods: this.fullState === "completed" ? "W" : "L",
        quote: "-",
        abilitySounds: "-",
        abilityText: "-",
        vgs: "-",
        spl: "-",
        feud: "-",
      });
      localStorage.setItem(
        "history",
        CryptoJS.AES.encrypt(JSON.stringify(history), this.cryptKey).toString()
      );
    }
  }
}
