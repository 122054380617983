import { NavigationStart, Router } from "@angular/router";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { DeviceDetectorService } from "ngx-device-detector";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Smite-Savvy";
  cryptKey = environment.cryptKey;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public deviceService: DeviceDetectorService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        setTimeout(() => {
          try {
            (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
          } catch (e) {
            console.error("ads", e);
          }
        }, 900);
      }
    });
  }
  aboutUs() {
    const dialogRef = this.dialog.open(AboutDialog, {
      height: "80%",
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  howtoplay() {
    let height = "80%";
    if (this.router.url.includes("ability")) height = "60%";
    let isYmir = false;
    if (this.router.url.includes("vgs") || this.router.url.includes("spl")) {
      let todayDuragYmir = localStorage.getItem("duragYmir_today");

      if (todayDuragYmir) {
        const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
        if (bytes.toString()) {
          let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          if (
            (duragYmirData.spot === "vgs" || duragYmirData.spot === "spl") &&
            duragYmirData.found === false
          ) {
            isYmir = true;
          }
        }
      }
    }
    this.dialog.open(HowToDialog, {
      height: height,
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
      data: { isYmir: isYmir },
    });
  }

  redirect(where) {
    if (where === "twitter")
      window.open("https://twitter.com/AstaThePasta", "_blank");
    if (where === "kofi")
      window.open("https://ko-fi.com/astathepasta", "_blank");
  }
  feudhelp() {
    let height = "55%";
    this.dialog.open(HowToDialog, {
      height: height,
      width: "60%",
      backdropClass: "dialog-background",
      scrollStrategy: new NoopScrollStrategy(),
      data: { title: "help" },
    });
  }
}

@Component({
  selector: "about-dialog",
  templateUrl: "about-dialog.html",
  styleUrls: ["about-dialog.scss"],
})
export class AboutDialog {}

@Component({
  selector: "howto-dialog",
  templateUrl: "howto-dialog.html",
  styleUrls: ["howto-dialog.scss"],
})
export class HowToDialog {
  cryptKey = environment.cryptKey;

  constructor(
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  duragYmir() {
    let todayDuragYmir = localStorage.getItem("duragYmir_today");

    if (todayDuragYmir) {
      const bytes = CryptoJS.AES.decrypt(todayDuragYmir, this.cryptKey);
      if (bytes.toString()) {
        let duragYmirData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        duragYmirData.found = true;

        localStorage.setItem(
          "duragYmir_today",
          CryptoJS.AES.encrypt(
            JSON.stringify(duragYmirData),
            this.cryptKey
          ).toString()
        );

        this.dialog.closeAll();
        this.router.navigateByUrl("/password");
      }
    }
  }
}
