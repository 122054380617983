export interface PasswordQuestion {
  question: string;
  condition: number;
  locked: boolean;
}

const passwordQuestions: PasswordQuestion[] = [
  {
    question: "Your password must be at least 5 characters",
    condition: 1,
    locked: true,
  },
  {
    question: "Your password must include a number",
    condition: 2,
    locked: true,
  },
  {
    question: "Your password must include an uppercase letter",
    condition: 3,
    locked: true,
  },
  {
    question: "Your password must include a special character",
    condition: 4,
    locked: true,
  },
  {
    question: "The numbers in your password should multiply to 667",
    condition: 5,
    locked: true,
  },
  {
    question: "Your password must include today's 'Guess The God' answer",
    condition: 6,
    locked: true,
  },
  {
    question:
      "Your password must include today's god name of the 2nd question of 'Guess The Ability Sound'",
    condition: 7,
    locked: true,
  },
  {
    question:
      "Your password must include today's playstation answer of the 3rd question of 'Guess The VGS Line'",
    condition: 8,
    locked: true,
  },
  {
    question:
      "Your password must include today's answer of the 4th question of 'SPL'",
    condition: 9,
    locked: true,
  },
  {
    question: "Which two gods are combined to make this abomination ?",
    condition: 10,
    locked: true,
  },
  {
    question: "Durag ymir is somewhere on this website, find him.",
    condition: 11,
    locked: true,
  },
];

export { passwordQuestions };
